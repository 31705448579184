/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #1353ab;
$success: #7cab3f;
$secondary: #8c9cbd;
// $danger: #d2232a;
// $warning: #dac803;

$navbar-height: 7vh;
$footer-height: 16vh;

.navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.navbar-brand {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.theme-navbar {
  min-height: $navbar-height;
  background-color: #fff;
  color: #000;

  // .nav-item {
  //     border-bottom: solid 3px #dac803;
  // }

  .nav-link {
    color: #000 !important;
  }

  .nav-item:hover .nav-link {
    color: #fff !important;
  }

  .nav-item:hover {
    text-decoration: none;
    background-color: #8c9cbd;
    border-radius: 3px;
  }
}

#wrapper {
  min-height: 100 - $navbar-height - $footer-height;
}

.theme-footer {
  min-height: $footer-height;
  background-color: #d6d6d6;
  color: #000;

  a {
    color: #000;
  }

  a:hover {
    color: #8c9cbd;
  }
}

.brand-logo {
  height: auto;
  width: 245px;
}

/* Loan Stats */
ul li {
  list-style: none;
}

.collapse-link {
  text-decoration: none;
  color: $primary;
  font-weight: bold;
}

.stats-info,
.related,
.usage {
  margin: 8px;
}

.related .col1 {
  display: inline-block;
  width: 10px;
  vertical-align: top;
  text-align: center;
}
.related .col2 {
  display: inline-block;
  width: 90%;
}

.usage .col1 {
  display: inline-block;
  width: 100px;
  padding: 0 5px;
  font-size: 0.8em;
}
.usage .col2 {
  display: inline-block;
  width: 50%;
  padding: 0 5px;
  font-size: 0.8em;
}

.col1 {
  text-align: right;
}

.col2 {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.col2 span {
  display: inline-block;
  width: 25px;
  text-align: right;
}

.col2 hr {
  margin-top: 1px;
  margin-bottom: 1px;
  margin-left: 5px;
  padding: 0;
  display: inline-block;
  height: 0.6em !important;
  border-radius: 0;
  border: 0;
}

hr.red {
  background-color: #ab2323;
  opacity: 1;
}

hr.green {
  background-color: #9fc01b;
  opacity: 1;
}

hr.blue {
  background-color: #24a2aa;
  opacity: 1;
}

#area {
  position: fixed;
  left: -999999px;
  top: -999999px;
}
.btnVerMas {
  margin-left: 5px;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss";
