.cover_card {
  min-width: 176px;
  margin: 5px;
}

.cover_card:hover {
  background-color: #eeebeb;
  transform: scale(1.05);
  transition: 0.7s;
  border-radius: 5px;
}

.cover_caption {
  cursor: pointer;
  margin-left: 4px;
}

.cover_card:hover .cover_caption {
  color: #000;
}

.cover_thumb {
  /* width: 10rem !important; */
  height: 15rem !important;
}

.mobile_drag{
  display: flex;
  overflow-x: auto;
  min-width: 100%;
  min-height: 200px;
}

.mobile_drag::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}

.mobile_drag::-webkit-scrollbar-track {
  background-color: #cecece;
  border-radius: 10px;
}

.mobile_drag::-webkit-scrollbar-thumb {
  background: #3f4248;
  border-radius: 10px;
}